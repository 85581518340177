<template>

    <div :class="{'top-modal-container' : position == 'top', 'bottom-modal-container' : position == 'bottom'}">
        <div  class="top-modal" :class="{'warning-bg' : mode == 'warning'}">
        <span class="mr-1">{{text}}</span>
        <button v-for="(button,index) in buttons" :key="index" @click="button.callback" :class="{'warning-button'  : mode == 'warning'}" class="modal-button ml-2 scaleanim">{{button.text}}</button>
    </div>
    </div>

</template>
<script>
export default {

    props: {
        text : String,
        buttons : Array,
        position : {type : String, default : 'top'},
        mode : {type:String, default : 'log'}
    }
    
}
</script>


<style scoped>

.warning-bg {
    background-color : #fb8c00 !important;
    color: white!important;
}

.warning-button {
    background-color : #FF3F34!important;
    border : 1px solid #FF3F34!important;
    color: white!important;
}

.bottom-modal-container {
    position : absolute;
    
    bottom : 1rem;
    left : 50%;
    transform : translateX(-50%);
    width : 95%;
    display : flex;
    align-items : center;
    justify-content : center;
}

.top-modal-container  {
    position : absolute;
    
    top : 1rem;
    left : 50%;
    transform : translateX(-50%);
    width : 95%;
    display : flex;
    align-items : center;
    justify-content : center;
}

.top-modal {
  
    z-index : 4;
   
  
    padding : 1rem;
    border-radius : 10px;
    background-color : white;
    font-family : 'Montserrat', sans-serif;
    font-size : 14px;
    letter-spacing : -0.02rem;
    color : black;
}

.modal-button  {
    padding : 0.25rem 1rem;
    border : 1px solid #0275d8;
    background-color : #0275d8;
    color : white;
    border-radius : 20px;
    outline: none!important;
}

</style>