<template>        
       <div ref="menu"  class="sim-etalon-menu">
            
            <div  ref="retractable"  class="sim-etalon-info">
                <span >1. <span style="font-weight:bold">Deplacez</span> les cercles <span style="color:#8e44ad; font-weight:bold">violets</span>  sur une ligne dont vous connaissez la mesure</span>
                <span>2. <span style="font-weight:bold">Tapez</span>  la mesure réelle de cette ligne <span style="font-weight:bold">en cm</span> (pas de chiffre après la virgule) </span>
                </div>
                <div class="sim-etalon-mesure">
                    <input ref="input" type="number" placeholder="Mesure">
                </div>
            
        </div>
    
</template>


<script>
export default {
    name: 'Etalon',
    props : {
        parent : Object,
        },
    data()
    {
        return {
            initialized : false,
            canvas : HTMLCanvasElement,
            ctx : null,
            circleRadius : 20,
            offset : 10,
            click : false,
            pos1 : [400,200],
            pos2 : [300,200],
            circleOne : new Path2D(),
            cirleTwo : new Path2D(),
        }
    },
    computed : {

        handleImg : function()
        {
            if(this.hide)return "transform : rotateZ(180deg)"
            return '';
        }
        },
    methods : {

        HideCanvas()
        {
            this.canvas.style.display = 'none';
        },
        ShowCanvas()
        {
            this.canvas.style.display = 'block';
        },

        SetVisible : function(val)
        {
            if(val) {
                this.ShowCanvas();}
            else 
            {
                console.log('coucou');
                this.HideCanvas();
            }
        },


        
        draw : function()
        {
            this.ctx.fillStyle = '#8e44ad';
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            this.ctx.beginPath();
            this.ctx.moveTo(this.pos1[0],this.pos1[1]);
            this.ctx.lineTo(this.pos2[0],this.pos2[1]);
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.beginPath();
            this.ctx.arc(this.pos1[0],this.pos1[1], this.circleRadius, 0, Math.PI * 2, true);
            this.ctx.arc(this.pos2[0],this.pos2[1], this.circleRadius, 0, Math.PI * 2, true);
            this.ctx.closePath();
            this.ctx.fill();

        },
        updatePos: function(event)
        {
           
            if (!this.click)return;
            let x =0;
            let y =0;
            if(event.type == 'mousemove')
            { 
                x = event.offsetX;
                y = event.offsetY;
            }
            if(event.type == 'touchmove')
            { 
                var bcr = event.target.getBoundingClientRect();
                x = event.targetTouches[0].clientX - bcr.x;
                y = event.targetTouches[0].clientY - bcr.y;
            }
            if(Math.pow(x-this.pos1[0],2)+Math.pow(y-this.pos1[1],2) < Math.pow(this.circleRadius + this.offset,2))   
             {
             this.pos1[0] = x;
             this.pos1[1] = y;
             this.draw();
             }
            if(Math.pow(x-this.pos2[0],2)+Math.pow(y-this.pos2[1],2) < Math.pow(this.circleRadius + this.offset,2))   
             {this.pos2[0] = x;
             this.pos2[1] = y;
             this.draw();
             }
            },
        ValidateEtalon : function(validate)
        {
            if(validate)
            {
                const screenDist = Math.sqrt(Math.pow(this.pos1[0]-this.pos2[0],2)+Math.pow(this.pos1[1]-this.pos2[1],2));
                const realDist = this.$refs.input.value;
                let ratio = realDist/screenDist*this.parent.bgRatio;
                //ratio = ratio * (this.parent.currentSize / this.parent.defaultSize);
                if(ratio != 0  && this.parent.neonHandler.current_background){
                    this.parent.neonHandler.current_background.ratio = ratio; 
                    this.parent.neonHandler.current_background.type = 1;
                
                }
                
                //console.log(screenDist + 'pixels représentent donc une distance réelle de ' + realDist + 'cm');
            }

            this.$refs.input.value = "";

        }, 
        Init(canvas)
        {
            this.canvas = canvas;
            console.log(this.parent.$refs.bg.offsetWidth);
            this.canvas.width = this.parent.$refs.bg.offsetWidth;
            this.canvas.height = this.parent.$refs.bg.offsetHeight;
            this.pos1[0] = this.canvas.width/2;
            this.pos1[1] = this.canvas.height/2;
            this.pos2[0] = this.canvas.width/2 + 50;
            this.pos2[1] = this.canvas.height/2;
            if (this.canvas.getContext) {
            this.ctx = this.canvas.getContext('2d');
    
            
            this.draw();
            this.canvas.addEventListener('mousemove', this.updatePos.bind(this));
            this.canvas.addEventListener('touchmove', this.updatePos.bind(this));
            this.canvas.addEventListener('mousedown', (() => {this.click = true;}).bind(this));
            this.canvas.addEventListener('touchstart', (() => {this.click = true;}).bind(this));
            this.canvas.addEventListener('mouseup', (() => {this.click = false}).bind(this));
            this.canvas.addEventListener('touchend', (() => {this.click = false}).bind(this));
            this.initialized = true;
        }
        },

        UpdateCanvasSize()
        {
            this.canvas.width = this.parent.$refs.bg.offsetWidth;
            this.canvas.height = this.parent.$refs.bg.offsetHeight;
             this.draw();
        }




    },


    mounted()
    {
        this.$refs.input.addEventListener('change', (event) => {
            event.stopPropagation();
        })
        this.$refs.input.addEventListener('click', (event) => {
            event.stopPropagation();
        })
    }
}
</script>


<style scoped>


.sim-etalon-menu {
    height: 100%;
    width: 100%;
    max-width: 900px;
    padding: 1rem;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    
}

.img-up {
    position: absolute;
    top: 1rem;
    transition-duration: 0.4s;
    right: 2rem;
    cursor: pointer;
}

.sim-etalon-menu > span {
        font-size: 1.25rem;
    margin: 0.5rem;
    overflow: hidden;
    transition-duration: 0.4s;
}

.sim-etalon-info {
    display: flex;
    flex-direction: column;
    transition-duration: 0.4s;
    overflow: hidden;
}

.sim-etalon-info > span
{
    margin: 0.25rem;
}

.sim-etalon-info > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0.25rem;
    align-items: center;
    justify-content: center;
}

.sim-etalon-mesure {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 0.2rem;
    flex-direction: column;
    align-items: center;
    transition-duration: 0.4s;
    margin-bottom: auto;
}
.sim-etalon-mesure > div
{
    border-radius: 30px;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    pointer-events : all;
    background-color:rgba(65, 124, 252,1);
    color:white;
    cursor: pointer;
}


.sim-etalon-mesure > input {
    padding: 0.5rem 0.75rem;
    margin-top: 1rem;
    pointer-events : all;
    border-radius: 30px;
    outline: none;
    border: 1px solid;
}


</style>

