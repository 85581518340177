<template>
      <div v-show="neonHandler.neon != null && neon.neon_id == neonHandler.neon.neon_id" ref="el" @mousedown="NeonDown" @touchstart="NeonDown" @mousemove="NeonMove" @touchmove="NeonMove" @mouseup="NeonUp" @touchup="NeonUp" @mouseleave="NeonUp" @touchleave="NeonUp" class="sim-neon"  :style="{'top' : y + 'px', 'left' :x + 'px','transform' : NeonScale}">
        <span ref='text'  :class="Class" :style="{'font-family' : neon.font + ', cursive' ,'color' : 'white','line-height':'100%' ,'font-size':FontSize, 'text-shadow' : Color, 'background':BackgroundColor, 'text-align' : neon.text_aligment}">{{neon.text}}</span>
        <!--<div v-if="useBaguette" :style="{'background-color' : baguetteColor}" class="plexi-baguette"></div>--> 
    </div>
</template>


<script>
/*
neon_index : int
dimension_id : int
color : String
support_color : String
font_id : int
support_id : int
text : String
unit_price : int
text_aligment : String
 */



export default {
    name: 'NeonView',
    props: {
        neon : null, 
        neonHandler : null,
        parent : null,
        
    },
    data() {
        return {
            x : 0 ,
            y : 0,
            visible : true,
            size : [0,0],
            tmp : [0,0],
            dimension : 10,
            down : false,
            rainbow : 0,
            interval_id : null,
            adapter : 1,
            update_adapter : false,
            scale : 1,
        }
    },
    computed : { 


        Color()
        {
            if(this.neon.color != "rainbow")return(`0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px ${this.neon.color}, 0 0 30px ${this.neon.color}, 0 0 40px ${this.neon.color}, 0 0 55px ${this.neon.color}, 0 0 75px ${this.neon.color}, 2px 2px 2px rgba(81,55,206,0)`)
            return(`0 0 5px #FFF, 0 0 10px #FFF, 0 0 15px #FFF, 0 0 20px ${"hsl(" + this.rainbow + ",100%,50%)"}, 0 0 30px ${"hsl(" + this.rainbow + ",100%,50%)"}, 0 0 40px ${"hsl(" + this.rainbow + ",100%,50%)"}, 0 0 55px ${"hsl(" + this.rainbow + ",100%,50%)"}, 0 0 75px ${"hsl(" + this.rainbow + ",100%,50%)"}, 2px 2px 2px rgba(81,55,206,0)`)
        
        },
        BackgroundColor()
        {
             if(this.neon.support_id != -1 )
            {
                return this.neonHandler.GetSupportColorFromId(this.neon.support_color_id);
            }
            return 'transparent';
        },
        FontSize()
        {
            //let dim = this.neonHandler.polices[this.neon.font_id].sizes[this.neon.dimension_id].cm;

            if(this.neonHandler.current_background.type == 1) return this.dimension*this.neon.font_ratio/this.neonHandler.current_background.ratio + "px";
            return this.neonHandler.GetDimensionFromId(0,this.neon.font_id)*this.neon.font_ratio*this.adapter/this.neonHandler.current_background.ratio /*(this.dimension*this.neon.font_ratio/this.parent.current_background.ratio)*/ + "px";
        },
        NeonScale()
        {
           //return "scale(1)"
             return "scale(" + this.scale + ")";
        },
         Class : function() {
            let classes = "";
            if(this.neon.support_id != -1)classes +="plexi-rect ";
            if(this.neon.support_id == 1) classes += "border-plexi ";

            return classes
        }

    },
    watch: {
        'neon.dimension_id' : 'GetDimension',
        'neon.text' : '_UpdatePixelDimension',
        'neon.font_id' : '_UpdatePixelDimension',
        'neonHandler.neon' : '_UpdatePixelDimension',
        'neonHandler.current_background' : '_UpdatePicelDimension',
        'neon.color' : 'HandleRainbow',
        'parent.bgRatio' : 'UpdateScale',

    },
 
    methods : {

        LaunchRainbow()
        {
            this.interval_id = setInterval(this.Rainbow, 15);
        },
        Rainbow()
        {
            this.rainbow++;
            if(this.rainbow >=255)this.rainbow = 0;
0
        },

        StopRainbow()
        {
            if(this.interval_id == null)return;
            clearInterval(this.interval_id);
            this.interval_id = null;
        },

        HandleRainbow()
        {
            if(this.neon.color == "rainbow" && this.interval_id == null)
            {
                this.LaunchRainbow();
            }
            else
            {
                this.StopRainbow();
            }
        },


        GetDimension()
        {
            this.dimension = this.neonHandler.GetDimensionFromId(this.neon.dimension_id, this.neon.font_id);
            this._UpdatePixelDimension();
        },

      UpdateScale()
      {
        const bg_width = this.parent.$refs.bg.offsetWidth * 0.8;


          this.scale = Math.min(1.2, bg_width/(this.$refs.text.offsetWidth))
          this.ClipNeon()

      },

      ClipNeon()
      {
        const rect = this.$refs.el.getBoundingClientRect();
        const bg_width = this.parent.$refs.bg.offsetWidth ;
        if(rect.right > bg_width)
        {
          this.x = bg_width - rect.width
        }
      },

        UpdatePixelDimension()
        {


            this._UpdatePixelDimension();
        },

        async _UpdatePixelDimension()
        {
            let instance = this;
            if(instance.$refs.text == undefined)return;
            if(this.neonHandler.neon == null)return;
            if(this.neonHandler.neon.neon_id != this.neon.neon_id)return;
            
            this.$nextTick(() => {
              this.UpdateScale();
              this.$nextTick(() => {

                let padding = 0;
                if(instance.neon.support_id == -1)padding-=16;
                /*if(!this.update_adapter)
                {
                    const bg_width = instance.parent.$refs.bg.offsetWidth * 0.8;

                const adapter_value = bg_width / (((instance.$refs.text.offsetWidth + padding*2)/this.adapter)*this.parent.bgRatio);
                if(adapter_value < 1){
                    this.adapter = adapter_value;
                    this.update_adapter = true;

                    return;
                    }
                }
                else {
                    this.update_adapter = false;
                }*/


                instance.size[0] = ((instance.$refs.text.offsetWidth + padding*2)/this.adapter)*(this.neonHandler.GetDimensionFromId(this.neon.dimension_id, this.neon.font_id)/this.neonHandler.GetDimensionFromId(0, this.neon.font_id))*instance.neonHandler.current_background.ratio;

                /*if(instance.neonHandler.current_background.type != 1)
              {

              //instance.size[1] = ((instance.$refs.text.offsetHeight*instance.neon.font_h_ratio + padding*2)/this.adapter)*(this.neonHandler.GetDimensionFromId(this.neon.dimension_id, this.neon.font_id)/this.neonHandler.GetDimensionFromId(0, this.neon.font_id))*instance.neonHandler.current_background.ratio ;
              }
              else {

              instance.size[0] = (instance.$refs.text.offsetWidth + padding*2)*instance.neonHandler.current_background.ratio;
              //instance.size[1] = (instance.$refs.text.offsetHeight*instance.neon.font_h_ratio + padding*2)*instance.neonHandler.current_background.ratio;

              }*/
                instance.neonHandler.SetSize(instance.size);
              });
            });
        },

        NeonDown(event)
        {
            this.down = true;
            this.neonHandler.SetNeon(this.neon.neon_id);

            if(event.type ==="mousedown")
            {
                this.tmp[0] = event.clientX;
                this.tmp[1] = event.clientY;
            }
            else
            {
                this.tmp[0] = event.touches[0].clientX;
                this.tmp[1] = event.touches[0].clientY;
            }
            this.parent.input.blur();

        },
        NeonUp()
        {
        this.down = false;

        },

      Clamp(num, min, max)
      {
        return Math.min(Math.max(num, min), max);
      },

        NeonMove(event)
        {
            event.preventDefault();
            event.stopPropagation();
            if(!this.down)return;
            this.ClipNeon();
            let e = event;
            if(event.type != 'mousemove')e=event.touches[0];
            let x_ = Math.round(this.$el.offsetLeft - (this.tmp[0] - e.clientX));
            let y_  = Math.round(this.$el.offsetTop - (this.tmp[1] - e.clientY));
            this.x = this.Clamp(x_, 0, (this.parent.$refs.bg.offsetWidth - this.$refs.el.offsetWidth * this.scale));
            this.y = y_;
            this.tmp[0] = e.clientX;
            this.tmp[1] = e.clientY;
           

        }

        
    },

    beforeDestroy()
    {
        this.$refs.text.removeEventListener('transitionend',this.UpdatePixelDimension);
    },

    mounted() {
        this.$refs.text.addEventListener('transitionend', this.UpdatePixelDimension);
        setTimeout(this._UpdatePixelDimension.bind(this), 4000);
        this.HandleRainbow();
        this.$nextTick(() => {
            this.x = this.parent.$refs.bg.offsetWidth/2 - this.$refs.el.offsetWidth/2;
        this.y = this.parent.$refs.bg.offsetHeight/2 - this.$refs.el.offsetHeight/2;
        })


    }
}
</script>

<style scoped>
.hide {
    display: none!important;
}




.sim-neon {
    display: flex;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  color: black;
  transform-origin: left;
  border-radius: 0px;
  cursor: grab;
  user-select: none;
  transition : 0.4s;
  transition-property: border-radius;
}

.plexi-rect {
        box-shadow: rgb(181, 181, 181) 0px 1px 0px, rgb(169, 169, 169) 0px 2px 0px, rgb(148, 148, 148) 0px 3px 0px, rgb(125, 125, 125) 0px 4px 0px, rgba(0, 0, 0, 0.23) 0px 0px 5px, rgba(0, 0, 0, 0.43) 0px 1px 3px, rgba(0, 0, 0, 0.4) 1px 4px 6px, rgba(0, 0, 0, 0.38) 0px 5px 10px, rgba(0, 0, 0, 0.25) 3px 7px 12px;

}

.plexi-baguette {
    position: absolute;
    height: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 110%;
 
    z-index: 1;
    border-radius: 30px;
            box-shadow: rgb(181, 181, 181) 0px 1px 0px, rgb(169, 169, 169) 0px 2px 0px, rgb(148, 148, 148) 0px 3px 0px, rgb(125, 125, 125) 0px 4px 0px, rgba(0, 0, 0, 0.23) 0px 0px 5px, rgba(0, 0, 0, 0.43) 0px 1px 3px, rgba(0, 0, 0, 0.4) 1px 4px 6px, rgba(0, 0, 0, 0.38) 0px 5px 10px, rgba(0, 0, 0, 0.25) 3px 7px 12px;
    background-color: rgba(0, 0, 0, 0.23);
}

.sim-neon:active {
  cursor: grabbing;
}

.sim-neon> span {
  font-size: 6rem;
  color: white;
  position: relative;
  white-space: pre;
  line-height: normal;
  display: inline-block;
  vertical-align: top;
  z-index: 3;
  padding: 1rem;
  transition-duration: 0.4s;
}

.border-plexi {
    border-radius: 10px;
}

.select {

outline: 2px solid red;
transition:none!important;

}

.selectMode:hover {
    outline: 2px solid red;
}

.selectModeAssembly {
    transition-duration: 0.4s!important;
}

.selectModeAssembly:hover {
    outline: 2px solid greenyellow;
}

.selectAssembly {

outline: 2px solid greenyellow;
transition:none!important;

}

.no-bg {
    background-color: transparent!important;
}


</style>