<template>
    <div class="wrapper-800">
      <div  ref="loading" :class="{'fade-out' : show, 'loading-page-error' : error_loading}"  class="loading-page">
        <div class="loading-page-content">
          <h1 v-if="!error_loading" class="loading">{{LoadingPercent}}</h1>
          <h1 v-else class="loading-error">{{error_message}}</h1>
          <span v-if="error_loading" class="error-sub">Veuillez rafraichir la page</span>

        </div>
      </div>
      <div id="SimContainer" class="sim-container" ref="sim">

      <div class="sim-bg-container">
        <div ref="bg"  class="sim-bg" @mousedown="FondDown" @touchstart="FondDown"  @mousemove="FondMove" @touchmove="FondMove" @mouseup="FondUp" @mouseleave="FondUp" @touchup="FondUp" :style="{'background-image' : Background,'background-color' : BackgroundColor,  'background-position' : backgroundPosition, 'background-size' : backgroundSize, 'cursor' : backgroundCursor}">
          <NeonView v-for="neon in neonHandler.neons" :key="neon.neon_id" :neonHandler="neonHandler" :neon="neon" :parent="instance" />
          <canvas id="canvas" >
            <span>Votre Navigateur ne supporte pas cette fonctionnalité</span>
        </canvas>
        </div>
        <transition  name="slide-fade" mode="out-in">
      <top-modal text="Le plein écran est recommandé." :buttons="full_buttons"  v-if="askfull && currentSize > 800"></top-modal>
      </transition>
        <transition  name="slide-fade" mode="out-in">
      <top-modal v-if="etalon_outdated" text="Vous devez redéfinir les mesures du fond." mode="warning" :buttons="outdated_button" ></top-modal>
      </transition>

        <div v-show="!EtalonMode && currentMenu !='positionnerFond'" class="sim-bg-button">


          <div class="fond-container"  @click="ShowImageAdder"> <SimButtonBlack   :urlImg="require('../assets/imgs/add_background.svg')" /></div>
          <div class="ml-auto panier-container">
            <SimButtonCTA :disabled="!first_action" :plain="true" @click.native="AddNeonToPanier" text="Ajouter au Panier" />
          </div>

        </div>

        <div class="montserrat spec-pricing-container" :style="{'right' : SpecPricing}">
          <div class="spec-pricing">Prix :</div>
          <div class="spec-pricing-prix">{{UpdatePrice}}€</div>
        </div>



      </div>
      <div v-show="!hideMenu" v-on:click="goTo('/')" ref="accueilBtn" class="sim-return"><img src="../assets/imgs/croix.svg" width="12" height="12" alt="" srcset=""></div>
      <!--<div v-if="handleFonds" v-on:click="RevealFonds" ref="fondsBtn" class="sim-return sim-fonds">Fonds disponibles</div>-->

      <div ref="menu" class="top-menu">
        <div id="settings" class="menu-settings">
          <div id="specs" class="menu-spec" :style="currentMenu ? 'left:0%' : 'left:100%'">
            <div id="colors" :class="{'sim-dropdown' : true, 'sim-show-menu' : currentMenu == 'colors', 'sim-hide-menu' : currentMenu != 'colors'}">
              <div class="dropdown-title">Couleurs</div>
            <div class="sim-colors-wrapper">
              <div  id="color-picker" ref="cPicker" class="sim-couleurs">


            <div v-for="color in calculateur.colors" :key="color.hex" class="sim-color-item">
              <div class="dot" :style="{'background-color' : color.hex}" @click="ColorPicked" :data-color="color.hex" :data-name="color.name"></div>
              <span :class="{'color-active' :( neonHandler.neon != null && color.hex == neonHandler.neon.color), 'color-text' : true}" >{{color.name}}</span>
            </div>
            <div class="sim-color-item">
              <div class="dot rainbow-bg" @click="ColorPicked"  data-color='rainbow'></div>
              <span :class="{'color-active' :( neonHandler.neon != null && 'rainbow' == neonHandler.neon.color), 'color-text' : true}" >RGB (+99€)</span>
            </div>
            </div>
            </div>

            <div class="mt-auto w-100 d-flex">
              <div class="sim-delete w-50" @click="Invalidate" ><img src="../assets/imgs/croix.svg" style="filter:brightness(0)" alt="" width="15" srcset=""></div>
              <div class="sim-validate w-50"  @click="Validate"><img src="../assets/imgs/validate.svg" style="filter:brightness(0)" alt="" width="40" srcset=""></div>

            </div>


          </div>
          <div id="polices" :class="{'sim-dropdown' : true, 'sim-show-menu' : currentMenu == 'polices', 'sim-hide-menu' : currentMenu != 'polices'}">
            <div class="dropdown-title">Polices</div>
            <div v-on:click="FontPicked" ref="_Polices" class="sim-polices">

              <span v-for="(font, index) in calculateur.polices" :key="index" :data-index="index" :style="{'font-family' : font.id + ',cursive','font-size' : neonHandler.GetDisplayFontSize(index), 'text-shadow' : GetFontSelectedStyle(index)}"  >{{font.props.display}}</span>


              <!-- span.style = `font-family:${Police.id},cursive;font-size:${fsize}rem`;
      span.setAttribute('data-index', i);
      span.innerHTML = Police.props.display;
      DOMPolices.appendChild(span);
       -->
            </div>
            <div class="mt-1 w-100 d-flex">
              <div class="sim-delete w-50" @click="Invalidate"><img src="../assets/imgs/croix.svg" style="filter:brightness(0)" alt="" width="15" srcset=""></div>
              <div class="sim-validate w-50" @click="Validate"><img src="../assets/imgs/validate.svg" style="filter:brightness(0)" alt="" width="40" srcset=""></div>

            </div>
          </div>

          <div id="supports" :class="{'sim-dropdown' : true, 'sim-show-menu' : currentMenu == 'supports', 'sim-hide-menu' : currentMenu != 'supports'}">
            <div class="dropdown-title">Support</div>
            <div v-if="!EtalonMode"  class="sim-support">
              <div @click="SupportPicked" ref="supportPicker" class="sim-support-img mt-3">
                <div><img data-index="0" src="../assets/imgs/rect.png" height="60px" alt="">
                <span :class="{'color-active' :( neonHandler.neon != null && 0 == neonHandler.neon.support_id), 'color-text' : true}">Rectangle</span></div>
                <div><img data-index="1" src="../assets/imgs/arrondi.png" height="60px" alt="">
                <span :class="{'color-active' :( neonHandler.neon != null && 1 == neonHandler.neon.support_id), 'color-text' : true}">Angles Arrondis</span></div>
                <div><img data-index="2" src="../assets/imgs/support_plexi.png" height="60px" alt="">
                <span :class="{'color-active' :( neonHandler.neon != null && 2 == neonHandler.neon.support_id), 'color-text' : true}">Support plexi {{ " (+" + calculateur.prices.supports.base + "€)"}}</span></div>
                <div><img data-index="3" src="../assets/imgs/pied.png" height="60px" alt="">
                <span :class="{'color-active' :( neonHandler.neon != null && 3 == neonHandler.neon.support_id), 'color-text' : true}">Pied plexi {{ " (+" + calculateur.prices.supports.pied + "€)"}}</span></div>
                <div><img data-index="-1" src="../assets/imgs/lettre.png" height="60px" alt="">
                <span :class="{'color-active' :( neonHandler.neon != null && -1 == neonHandler.neon.support_id), 'color-text' : true}">Découpe à la lettre</span></div>
              </div>
              <div class="color-support">
              <div class="dropdown-title">Couleur du support</div>
              <div class="sim-colors-wrapper">
                <div v-on:click="SupportColorPicked" class="sim-couleurs">
                  <div v-for="(background,index) in calculateur.supportColors" :key="index" class="sim-color-item">
                    <div class="dot" :style="{'background' : background.color}" :data-color="background.color" :data-index="background.name.toLowerCase()"></div>
                    <span :class="{'color-active' :( neonHandler.neon != null && background.name.toLowerCase() == neonHandler.neon.support_color_id), 'color-text' : true}">
                      {{background.name +
                        (neonHandler.calculateur.prices.support_colors[background.name.toLowerCase()] == undefined ? ""
                      : '(+' + neonHandler.calculateur.prices.support_colors[background.name.toLowerCase()] + '€)' )  }}  </span>
                  </div>


              </div>
              </div>
            </div>
            </div>
            <small class="mt-auto w-100 px-3" style="font-family:Montserrat">La couleur du support de sera pas visible pour le support <b>Découpe à la lettre</b></small>
            <div class="mt-1 w-100 d-flex">
              <div class="sim-delete w-50" @click="Invalidate"><img src="../assets/imgs/croix.svg" style="filter:brightness(0)" alt="" width="15" srcset=""></div>
              <div class="sim-validate w-50" @click="Validate"><img src="../assets/imgs/validate.svg" style="filter:brightness(0)" alt="" width="40" srcset=""></div>

            </div>

          </div>
          <div id="dimensions" :class="{'sim-dropdown' : true, 'sim-show-menu' : currentMenu == 'dimensions', 'sim-hide-menu' : currentMenu != 'dimensions'}">
            <div class="dropdown-title">Dimensions</div>
            <div v-if="!EtalonMode"  class="sim-support">
            <div style="" class="sim-align">
              <div  ref="dimlist" class="dimensions-list">
                <RoundButton text="XS" color="#3E91F5" :index="0" :neonHandler="neonHandler" :callBack="DimensionPicked" />
                <RoundButton text="S" color="#FF4440" :index="1" :neonHandler="neonHandler" :callBack="DimensionPicked" />
                <RoundButton text="M" color="#1A1A1A" :index="2" :neonHandler="neonHandler" :callBack="DimensionPicked" />
                <RoundButton text="L" color="#FFCC64" :index="3" :neonHandler="neonHandler" :callBack="DimensionPicked" />

              </div>
               <span class="my-2 px-2" style="font-size:12px">Les valeurs ci-dessus correspondent à la hauteur moyenne d'un caractère minuscule en centimètre</span>
             <div class="dimensions-size">
               <span>Taille actuelle :</span>
                <span style="font-weight:bold">{{Dimension}}</span>

               </div>




            </div>
            </div>
            <!--<span class="mt-auto montserrat" style="font-size:15px">Besoin d'une dimension particulière ?</span>
               <div class="sim-button-1 mt-2 scaleanim montserrat">Je demande un devis</div>-->

            <div class="mt-auto w-100 d-flex">
              <div class="sim-delete w-50" @click="Invalidate"><img src="../assets/imgs/croix.svg" style="filter:brightness(0)" alt="" width="15" srcset=""></div>
              <div class="sim-validate w-50" @click="Validate"><img src="../assets/imgs/validate.svg" style="filter:brightness(0)" alt="" width="40" srcset=""></div>

            </div>
          </div>

          <div id="options" :class="{'sim-dropdown' : true, 'sim-show-menu' : currentMenu == 'options', 'sim-hide-menu' : currentMenu != 'options'}">
            <div class="sim-options">
              <div class="dropdown-title">Options</div>
              <span class="mt-2">Type de néon : </span>
              <div class="neon-type" >
                <SimButton1   :callBack="NeonTypePicked" text="Intérieur" ref="int" />
                  <SimButton1  :callBack="NeonTypePicked"  text="Extérieur" ref="ext"/>

               <!-- <div style="margin-right:0.5rem" data-id="int">Intérieur</div>
                <div style="margin-left:0.5rem" data-id="ext">Exterieur</div>-->
              </div>
              <div  class="neon-tel">
                <span>Télécommande :</span>  <CheckBox ref="telecommande" :callBack="TelecommandePicked"/>
                </div>
                <span style="margin-top:1rem" >Type de prise :</span>
                <div v-on:click="PrisePicked" class="sim-prises">
                  <div>
                    <img src="../assets/imgs/europe.png" width="50" height="50" alt="" data-index="0">
                    <span :class="{'color-active' :( neonHandler.neon != null && 0 == neonHandler.neon.options.prise_id), 'color-text' : true}">Europe</span>
                  </div>
                  <div>
                  <img src="../assets/imgs/usa.png" width="50" height="50" alt="" data-index="1">
                    <span :class="{'color-active' :( neonHandler.neon != null && 1 == neonHandler.neon.options.prise_id), 'color-text' : true}">USA</span>
                    </div>
                    <div>

                    <img src="../assets/imgs/anglais.png" width="50" height="50" alt="" data-index="2">
                      <span :class="{'color-active' :( neonHandler.neon != null && 2 == neonHandler.neon.options.prise_id), 'color-text' : true}">UK</span>
                    </div>
                </div>

                <div class="d-flex justify-content-center py-3 align-items-baseline w-100">
                  <div class="d-flex flex-column align-items-start mr-2">
                  <span style="text-align:initial">Couleur du fil:</span>

                  </div>
                  <select class="form-select form-select-sm" v-model.number="fil_type" @change="FilPicked">
                  <option  v-for="(fil,index) in calculateur.fils" :key="index" :value="index">{{fil}}</option>

                </select>


                </div>
              <span class="sim-message-title ">Commentaire :</span>
              <div class="sim-message">

                <textarea class="form-control" @input="UpdateNeonMessage" placeholder="Tapez ici toutes précisions que vous voudriez nous faire parvenir..." :value="Message"></textarea>

              </div>
            </div>


            <div class="mt-auto w-100 d-flex">
              <div class="sim-validate" @click="Validate"><img src="../assets/imgs/validate.svg" style="filter:brightness(0)" alt="" width="40" srcset=""></div>

            </div>

          </div>
          <div id="fonds" :class="{'sim-dropdown' : true, 'sim-show-menu' : currentMenu == 'fonds', 'sim-hide-menu' : currentMenu != 'fonds'}">
            <div class="dropdown-title">{{handleFondTitle}}</div>
            <div v-if="!EtalonMode" class="sim-align">
              <div class="fonds-container">
                <div  class="fond-item" v-for="(background, index) in neonHandler.backgrounds" @click="SetBackground(index)" :key="index">
                  <img v-if="background.type != 'color'" :src="background.url" alt="" srcset="">
                  <div v-else :style="{'background-color'  : background.color}"></div>
                </div>
              </div>
              <div v-if="handlePhone" v-on:click="HandleFonds" :class="{'sim-button-classic' : true,'mb-1' : true}" >Ajouter un fond</div>
              <div v-if="handlePhone" @click="PosFondPicked" class="sim-button-classic  mt-3" >Positionner votre fond</div>
            </div>
            <div :style="{display:handleEtalon, 'height' : '100%' }">
              <Etalon ref="etalon" :parent="instance" :canvas="canvas" />
            </div>


             <div class="mt-auto w-100 d-flex">
               <div class="sim-delete w-50" data-id="invalidate" @click="InvalidateFond" ><img src="../assets/imgs/croix.svg" style="filter:brightness(0)" alt="" width="15" srcset=""></div>
              <div class="sim-validate w-50" data-id="validate" @click="ValidateFond" ><img src="../assets/imgs/validate.svg" style="filter:brightness(0)" alt="" width="40" srcset=""></div>

            </div>

          </div>
          <div  :class="{'sim-dropdown' : true, 'sim-show-menu' : currentMenu == 'positionnerFond', 'sim-hide-menu' : currentMenu != 'positionnerFond'}">
            <div class="dropdown-title">Positionner le fond</div>
            <div class="sim-align">
              <span class="mt-0 px-3 neon-type">Pour positionner votre fond, il vous suffit de cliquer et déplacer ce dernier :)</span>
              <span>Zoom :</span>
              <div class="w-100 px-3 d-flex flex-row justify-content-center mt-0">
              <RangeSlider :min="10" @valuechanged="ChangeBackgroundSize" />
              </div>

            </div>


             <div class="mt-auto w-100 d-flex">
               <div class="sim-delete w-50" data-id="invalidate" @click="InvalidatePosFond" ><img src="../assets/imgs/croix.svg" style="filter:brightness(0)" alt="" width="15" srcset=""></div>
              <div class="sim-validate w-50" data-id="validate" @click="ValidatePosFond" ><img src="../assets/imgs/validate.svg" style="filter:brightness(0)" alt="" width="40" srcset=""></div>

            </div>

          </div>




          </div>

          <div id="base" :style="currentMenu ? 'pointer-events:none' : 'pointer-events:all'" class="menu-base">

           <!-- <div class="sim-onglets-container">
              <div class="sim-onglets-double-wrapper">
                <div class="sim-onglets-wrapper" ref="onglets">
                <div class="sim-onglet" id="text_onglet" style="z-index:1">Texte</div>
                <div class="sim-onglet sim-onglet-bottom" id="motif_onglet" style="z-index:1">Motifs</div>
                </div></div>


            </div> -->

            <div class="onglet-neon-container">
              <div ref="ongletsneon" v-on:click="OngletPicked" class="onglet-neon-content">

              <div class="onglet-neon" style="width:auto" :class="{'sim-delete-disabled' : neonHandler.neons.length >= 3}" @click="AddNeonPicked" >+</div>
              <div v-for="neon in neonHandler.neons" :class="{'onglet-neon' : true, 'onglet-neon-active' : neonHandler.neon != null && neon.neon_id == neonHandler.neon.neon_id}" :key="neon.neon_id" :data-index="neon.neon_id" @click="OngletPicked" ><div class="onglet-neon-dot" :data-index="neon.neon_id"  :style="{'background' : neon.color == 'rainbow' ? gradient : neon.color }"></div></div>
              </div>
            </div>
        <div class="sim-input-container">
          <div class="sim-input">
        <textarea :maxlength="StopChar" id="inputT" @input="TextPicked" :value="Text"  type="text" placeholder="Tapez votre Texte..."/>

        </div>
        <span style="margin-bottom:1rem">Il vous reste <span ref="count">{{Characters}}</span> caractères</span>
        </div>

        <div class="sim-menu-fonction">
          <div data-menu="polices" class="sim-button"><div style="background-color:#F6F6F6" class="menu-img-debug"><img src="../assets/imgs/_polices.png" width="71" height="71" alt="" srcset=""></div><span >Polices</span></div>
        <div data-menu="colors" class="sim-button"><div style="background-color:#F6F6F6" class="menu-img-debug"><img src="../assets/imgs/_couleurs.png" width="71" height="71" alt="" srcset=""></div><span >Couleurs</span></div>
        <div class="sim-button" data-menu="supports"><div style="background-color:#F6F6F6" class="menu-img-debug"><img src="../assets/imgs/_supports.png" width="71" height="71" alt="" srcset=""></div><span>Support</span></div>
        <div class="sim-button" data-menu="dimensions"><div style="background-color:#F6F6F6" class="menu-img-debug"><img src="../assets/imgs/_dimensions.png" width="71" height="71" alt="" srcset=""></div><span>Dimensions</span></div>
        <div class="sim-button" data-menu="options"><div style="background-color:#F6F6F6" class="menu-img-debug"><img src="../assets/imgs/_options.png" width="71" height="71" alt="" srcset=""></div><span>Options</span></div>
        <div class="sim-button" v-show="display_fond && handlePhone" data-menu="fonds"><div style="background-color:#F6F6F6" class="menu-img-debug"><img src="../assets/imgs/_fond.png" width="71" height="71" alt="" srcset=""></div><span>Fonds</span></div>
        </div>

        <div class="pricing">
          <span>Prix :</span>
          <span class="sim-price">{{UpdatePrice}}€</span>
        </div>


        <div :class="{'sim-delete' : true, 'sim-delete-disabled' : !neonHandler.neon}" @click="RemoveNeonPicked" style="margin-top:0px"><img src="../assets/imgs/croix.svg" style="filter:brightness(0)" alt="" width="15" srcset=""></div>

       <!-- <input type="button" class="btn btn-danger save-button" value="Enregistrer une image" v-on:click="TakeScreenShot">-->
          </div>
        </div>

         <!-- <div class="pricing">
          <span class="sim-price">Prix actuel: <span>{{UpdatePrice}}€</span></span>
          <SimButtonCTA href="/Panier" text="Ajouter au Panier" />
        </div> -->









      </div>




       <mini-modal ref="logger">
              <div class="article-modal-content">
                <span>Votre neon a été ajouté au Panier !</span>
                <button @click="GoToPanier" class="article-panier-button mt-1" >Voir mon panier</button>
                <button @click="HideLogger" class="article-panier-button mb-1" >Continuer sur le site</button>
              </div>
          </mini-modal>
        <mini-modal ref="info" :NoHeight="true">
          <span class="sim-info m-3">N'oubliez pas d'essayer les polices proposées<b> EN MAJUSCULE !</b></span>
        </mini-modal>
      <mini-modal  ref="addImage">
        <images-adder ref="drag" :urls="neonHandler.backgrounds" validate_text="Ajouter le fond" @newurlsajout="AddNewBackground"  :callBack="ShowEtalon" :parent="instance" />
      </mini-modal>
      <mini-modal ref="images" title="Fonds disponibles" :noButton="true" messageStyle="font-size:0.75rem;margin-bottom:0rem" message="En cliquant sur l'image + vous aurez la possibilité d'ajouter votre propre fond d'écran">
        <ChooseBackground id="backgrounds" :backgrounds="neonHandler.backgrounds" :parent="instance"/>
      </mini-modal>
      <!--<MiniModal ref="neon_type" :condition="modals[0]" :index="0" :callBack="ModalClosed" title="Type de néon ?" text="Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet" />
     <MiniModal ref="prise_type" :condition="modals[0]" :index="0" :callBack="ModalClosed" title="Type de prise ?" text="Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet Lorem Ipsum dolor sit amet" />-->

    </div>


    </div>
</template>


<script>

import Etalon from '../components/Etalon'
import NeonView from '../components/NeonView'

import MiniModal from '../components/MiniModal'
import TopModal from '../components/TopModal'
import ImagesAdder from '../components/ImagesAdder'
import ChooseBackground from  '../components/ChooseBackground'
import {Background} from  '../components/ChooseBackground'
import {SimButton1,SimButtonCTA,CheckBox, SimButtonBlack,RoundButton,RangeSlider} from '../components/buttons'
export default {
    name: 'Simulateur',
    metaInfo: {
     meta: [{
    vmid: 'description',
    name: 'description',
    content: "Creéz votre néon LED personnalisé de A a Z et recevez le dans un délai de 3 semaines ",
  },
                { property: 'og:title', content: "Créez votre Néon Personnalisé"},
                { property: 'og:site_name', content: 'Neon Bloom Paris'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'index,follow'},
                {'http-equiv':'x-ua-compatible', content:'IE-edge'}

                ],

      title: 'Créez votre Néon Personnalisé',
    },
    components : {

      CheckBox,

      ImagesAdder,
      ChooseBackground,
      Etalon,
      SimButton1,
      SimButtonCTA,
      SimButtonBlack,
      NeonView,
      RoundButton,
      MiniModal,
      RangeSlider,
      TopModal

    },


      computed : {


        Message()
        {
          if(this.neonHandler.neon == null)return "";
          return this.neonHandler.neon.message;
        },

        Background()
        {
          if(!this.background_loaded)return "";
          if(this.neonHandler.current_background.type == "color")return ""
          return ("url(" + this.neonHandler.current_background.url + ")");
        },

        BackgroundColor()
        {
           if(this.neonHandler.current_background.type == "color")return this.neonHandler.current_background.color;
           return "transparent";
        },

        SpecPricing()
        {
          if(this.currentMenu && this.currentMenu != 'fonds' && this.currentMenu != 'positionnerFond')return"0px";
          return "-130px"
        },

        Text()
        {
          if(!this.neonHandler.neon)return "";
          return this.neonHandler.neon.text;
        },

        Characters() {
          if(!this.neonHandler.neon)return 20;
         let tmp = this.replaceAll(this.neonHandler.neon.text," ", "");
          tmp = this.replaceAll(tmp,"\r", "");
          tmp = this.replaceAll(tmp,"\b", "");
          tmp = this.replaceAll(tmp,"\n", "");
          return 20 - tmp.length;
        },

        StopChar()
        {
          if(!this.neonHandler.neon)return 20;

          let tmp = this.replaceAll(this.neonHandler.neon.text," ", "");
          tmp = this.replaceAll(tmp,"\r", "");
          tmp = this.replaceAll(tmp,"\b", "");
          tmp = this.replaceAll(tmp,"\n", "");
          if(20 - tmp.length == 0)
          {
            return this.neonHandler.neon.text.length;
          }
          return 100;
        },

        UpdatePrice()
        {
          if(!this.first_action)return 0.00
          if(!this.neonHandler.neon)return "";
          return this.neonHandler.neon.unit_price.toFixed(2);
        },

        backgroundButton()
        {
          if(this.neonHandler.current_background != null)
          {
            if(this.neonHandler.current_background.type == -1)return"Ajouter un fond";

          }
          return"Définir les mesures";
        },

        backgroundPosition()
        {
          if(!this.neonHandler.current_background)return "center";
          return (this.neonHandler.current_background.x + 'px ' + this.neonHandler.current_background.y+ 'px ' );
        },
        backgroundSize()
        {
          if(!this.neonHandler.current_background)return "container";
          return (this.neonHandler.current_background.size  * this.currentBgSize + 'px ' );
        },

        backgroundCursor()
        {
          if(this.currentMenu == "positionnerFond")return "grab";
          return "default"
        },

        handleFondTitle()
        {
          if(this.EtalonMode)return "Définition de l'étalon";
          return  'Fonds';
        },

        Dimension()
        {
          if(!this.neonHandler.neon)return;
          return (' ' + Math.round(this.neonHandler.neon.width) + ' x ' + Math.round(this.neonHandler.neon.height) + 'cm');
        },


      handlePhone()
      {
        return(window.innerWidth >= 800);
      },
      handleEtalon()
      {
        if(this.EtalonMode)return"flex";
        return "none";
      },
      handleFonds()
      {
        return(this.handlePhone && !this.hideMenu);
      },
      showSupportColor : function()
      {
        if (!this.neon) return false;
        if (this.neon.support_id != -1) return true;
        return false;
      },


      handleDimension()
      {
        if(!this.neonHandler.neon)return "";
        return(Math.round(this.neonHandler.neon.width) + 'x' + Math.round(this.neonHandler.neon.width) + 'cm');
      },

      LoadingPercent()
      {
        let p = 0;
        if(this.calculateur.policesLoaded)p+=20;
        if(this.calculateur.pricesLoaded)p+=20;
        if(this.calculateur.colorsLoaded)p+=20;
        if(this.calculateur.defaultNeonLoaded)p+=20;
        if(this.calculateur.policesLoaded && this.calculateur.pricesLoaded && this.calculateur.colorsLoaded && this.calculateur.defaultNeonLoaded)p="Chargement du fond";
        if(p.length > 2)return p;
        return p + '%';
        /*this.policesLoaded = false;
        this.pricesLoaded = false;
        this.colorsLoaded = false;
        this.defaultNeonLoaded = false; */
      },

      ShowLoadingPage()
      {
        if(!this.calculateur.policesLoaded ||
           !this.calculateur.pricesLoaded ||
           !this.calculateur.colorsLoaded ||
           !this.calculateur.defaultNeonLoaded || !this.background_loaded )return true;

           return false;
      }


    },
    watch: {
      'neonHandler.neon' : 'UpdateNeon',
      },
    data () {
  return {

    /***################## */
      neonHandler : this.$neonHandler,
      calculateur : null,
      instance :this,
      modals : [true,false,false,false],
      specPricing : false,
      currentMenu : null,
      first_action : false,
      fondDown : false,
      fondStartPos : [0,0],
      etalon_outdated : false,
      gradient : "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)",
    /**################# */
    specs : [],
    currentBackground : null,
    defaultBgSize : 0,
    currentBgSize : 0,
    bgRatio : 0,
    fil_type : 0,
    hideMenu : false,
    DeleteMode : false,
    EtalonMode : false,
    AlignMode : false,
    rgbColor : "#FFFFFF",
    buttons : [],
    menu : null ,
    base : null,
    display_fond : true,
    canvas : null,
    error_loading : false,
    error_message : "Erreur inconnue",
    /*########### */
    background_loaded : false,
    show : false,
    askfull : false,
    full_buttons : [{text : "J'y vais", callback : this.GoFullscreen},{text : "Non merci", callback : this.DimissAsk}],
    outdated_button : [{text : "C'est noté", callback : this.Noted}]
  }
},
methods : {

  FirstAction()
  {

    if(this.first_action)return;
    this.$storageHandler.LogSim();
    this.first_action = true;
  },

   escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
},
 replaceAll(str, match, replacement){
   return str.replace(new RegExp(this.escapeRegExp(match), 'g'), ()=>replacement);
},

  GoFullscreen()
  {
    this.$el.requestFullscreen();
    this.askfull = false;
  },
  DimissAsk()
  {
    this.askfull = false;
  },



  UpdateNeonMessage(event)
  {
    this.neonHandler.SetMessage(event.target.value)
  },

  GoToPanier()
  {
    this.$router.push('/Panier');
  },

  HideLogger()
  {
     this.$refs.logger.Hide()
  },

  AddNeonToPanier()
  {
    if(!this.first_action)return;
    let validate = this.neonHandler.CheckNeon();
    if(!validate)return;
    this.$panierHandler.AddNeonToPanier(this.neonHandler.neon);
    this.$refs.logger.Reveal('Article ajouté', "", 460,"#16a085");

  },

  ExitFullscreen()
  {


    if (document.fullscreenElement !=null || document.mozFullScreenElement!=null || document.webkitFullscreenElement != null)
    {
      console.log();
    }
    else {
      this.askfull = true;
    }

  },


  ChangeBackgroundSize(value)
  {
    this.neonHandler.current_background.size = value / 50;
  },

  SetBackground(index)
  {
    this.background_loaded = false;
    this.neonHandler.SetBackground(index);
    if(this.neonHandler.backgrounds[index].type == "color")
    {
      this.background_loaded = true;
      return;
    }
    return this.LoadBackground(this.neonHandler.current_background.url);
  },

  LoadBackground(url)
  {
    return new Promise((resolve) => {
      let preloaderImg = document.createElement("img");

    preloaderImg.addEventListener('load', () => {
      this.background_loaded = true;
      resolve();
    })
    preloaderImg.src = url;
});
  },


  AddNewBackground(urls)
  {
    this.HideImageAdder();
    for(let i = 0; i < urls.length; i++)
    {
      this.neonHandler.backgrounds.push(urls[i]);
    }
    this.SetBackground(this.neonHandler.backgrounds.length-1);
  },

  SetDefaultBackground(url)
  {
    return new Promise((resolve) => {
    let bg = new Background(url, 0.15, -1);
    this.neonHandler.backgrounds.push(bg);
    this.SetBackground(this.neonHandler.backgrounds.length-1).then(() => {
      resolve();
    });
    })
  },

  ShowImageContainer()
  {
    this.$refs.images.Reveal("Vos fonds");
  },

  HideImageContainer()
  {
    this.$refs.images.Hide();

  },
  ShowImageAdder()
  {
    this.HideImageContainer();
    this.$refs.addImage.Reveal("Ajouter un fond");
    this.display_fond = true;

  },
  HideImageAdder()
  {
    this.$refs.addImage.Hide();

  },

  OngletPicked(event)
  {

    this.FirstAction();
    if(!event.target.dataset.index)return;
    this.neonHandler.SetNeon(event.target.dataset.index);
  },


  AddNeonPicked()
  {
    this.FirstAction();
    this.neonHandler.AddDefaultNeon.bind(this.neonHandler)();
  },

  ColorPicked(event)
  {
    this.FirstAction();
    this.neonHandler.SetColor(event.target.dataset.color, event.target.dataset.name);
  },
  FontPicked(event)
  {
    this.FirstAction();
    if(!event.target.dataset.index)return;
    this.neonHandler.SetFont(event.target.dataset.index);
  },
  TextPicked(event)
  {

    this.FirstAction();
    this.neonHandler.SetText(event.target.value);
  },
  SupportPicked(event)
  {

    if(!event.target.dataset.index)return;
    this.FirstAction();
    this.neonHandler.SetSupport(event.target.dataset.index);
  },
  SupportColorPicked(event)
  {

    if(!event.target.dataset.index)return;
    this.FirstAction();
    this.neonHandler.SetSupportColor(event.target.dataset.index);
  },
  DimensionPicked(event)
  {

    if(!event.target.dataset.index)return;
    this.FirstAction();
    this.neonHandler.SetDimension(event.target.dataset.index)
  },
  PrisePicked(event)
  {

    if(!event.target.dataset.index)return;
    this.FirstAction();
    this.neonHandler.SetPrise(event.target.dataset.index);
  },
  TelecommandePicked(val)
  {
    this.neonHandler.SetTelecommande(val);
  },
  FilPicked(event)
  {

    this.neonHandler.SetFil(parseInt(event.target.value));
  },
  NeonTypePicked(val, text)
  {
    if(text == 'Intérieur')
    {
      this.$refs.ext.SetValue(!val);
      if(val)this.neonHandler.SetNeonType(0);
      else {
        this.neonHandler.SetNeonType(1);
        }
    }
    else {
      this.$refs.int.SetValue(!val);
      if(val)this.neonHandler.SetNeonType(1);
      else {
        this.neonHandler.SetNeonType(0);
        }
    }

  },
  AlignmentPicked(event)
  {
    this.FirstAction();
    if(!event.target.dataset.index)return;
    this.neonHandler.SetAlignment(event.target.dataset.index);
  },

  HelpPicked(event)
  {
    switch (event.target.dataset.index)
    {
      case "0" : this.$refs.neon_type.Reveal();break;
      case "1" : this.$refs.prise_type.Reveal();break;
    }
  },


  GetFontSelectedStyle(index)
  {
    if(this.neonHandler.neon != null && this.neonHandler.neon.font_id == index)return '3px 2px 2px '+this.neonHandler.neon.color;
    return 'none';

  },

  RemoveNeonPicked()
  {
    this.FirstAction();
    this.neonHandler.RemoveNeon(this.neonHandler.neon.neon_id);
  },

/**GESTION DES CHANGEMENTS ENTRE NEON */
  UpdateNeon()
  {
    if(!this.neonHandler.neon)return;

    this.$refs.telecommande.SetValue(this.neonHandler.neon.options.telecommande);
    this.fil_type = this.neonHandler.neon.options.fil_id;
    if(this.neonHandler.neon.options.neon_type_id == 0)
    {
      this.$refs.int.SetValue(true);
      this.$refs.ext.SetValue(false);
    }
    else
    {
      this.$refs.int.SetValue(false);
      this.$refs.ext.SetValue(true);
    }
  },
  HideNeons()
  {
    this.neonHandler.SetNeon(null);
  },

/**GESTION DES MODALS */
  ModalClosed(event, index)
  {
    this.modals[index] = false;
  },

  PosFondPicked()
  {
    this.currentMenu="positionnerFond";
    this.neonHandler.current_background.Save();
    this.HideNeons();
  },

  FondDown(event)
  {
    if(this.currentMenu != 'positionnerFond')return;
    this.fondDown = true;
    if(event.type ==="mousedown")
            {
                this.fondStartPos[0] = event.clientX;
                this.fondStartPos[1] = event.clientY;
            }
            else
            {
                this.fondStartPos[0] = event.touches[0].clientX;
                this.fondStartPos[1] = event.touches[0].clientY;
            }
  },
  FondMove()
  {
    if(this.currentMenu != 'positionnerFond' || this.fondDown == false)return;
    let e = event;
    if(event.type != 'mousemove')e=event.touches[0];

    let x = Math.round(this.neonHandler.current_background.GetX() - (this.fondStartPos[0] - e.clientX));
    let y  = Math.round(this.neonHandler.current_background.GetY() - (this.fondStartPos[1] - e.clientY));
    this.neonHandler.current_background.SetPosition(x,y);
    this.fondStartPos[0] = e.clientX;
    this.fondStartPos[1] = e.clientY;
},
  FondUp()
  {
    if(this.currentMenu != 'positionnerFond')return;
    this.fondDown = false;
  },
  FondSize(event)
  {
    this.neonHandler.current_background.size += parseInt(event.target.dataset.value);
  },
















  /*GESTION DES MENUS*/
   ShowMenu : function(menu)
    {

      this.currentMenu = menu;
      if(menu != 'fonds' && this.currentMenu!="positionnerFond")this.neonHandler.EnterTmpMode()
    },

   HideMenu : function(validate)
    {

      if(this.currentMenu != 'fonds' && this.currentMenu!="positionnerFond")this.neonHandler.QuitTmpMode(validate);
      this.currentMenu = null;
    },

  Validate() {
    this.HideMenu(true);
  },
  Invalidate(){
    this.HideMenu(false);
  },

  ValidateFond(event)
  {
    if(this.EtalonMode){

      this.$refs.etalon.ValidateEtalon(true);
      this.HideEtalon(event);
    }
    else{
      this.Validate(true);
    }
  },
  InvalidateFond(event)
  {
    if(this.EtalonMode){

      this.$refs.etalon.ValidateEtalon(false);
      this.HideEtalon(event);
    }
    else{
      this.Invalidate();
    }
  },

  Noted()
  {
    this.etalon_outdated = false;
  },

  ValidatePosFond()
  {
    this.neonHandler.current_background.Save();
    if(this.neonHandler.current_background.type == 1)
    {
      this.etalon_outdated = true;
      if(this.askfull)this.askfull = false;
      this.neonHandler.current_background.type = 0;

    }
    this.neonHandler.SetNeon(this.neonHandler.lastNeon.neon_id);
    this.Validate(true);
  },
  InvalidatePosFond()
  {
    this.neonHandler.current_background.Revert();
    this.neonHandler.SetNeon(this.neonHandler.lastNeon.neon_id);
    this.Invalidate(true);
  },




  /*GESTION DES DIMENSIONS */





  /*GESTIONS FONDS D'ECRAN */

    ShowEtalon()
    {
      this.HideImageAdder();
      this.neonHandler.SetNeon(null);
      this.EtalonMode = true;
      this.currentMenu = "fonds";
      this.$refs.etalon.SetVisible(true);

    },
    HideEtalon(event)
    {
      event.stopPropagation();
      if(this.EtalonMode) {
        this.EtalonMode = false;
        this.$refs.etalon.SetVisible(false);
        this.neonHandler.SetNeon(this.neonHandler.lastNeon.neon_id);

      }


    },




    HandleFonds()
    {
      this.ShowImageAdder();
    },




    /**DIVERS */


     goTo : function(path)
        {
            this.$router.push(path);
        },



    ResizeListener()
    {
      this.$refs.etalon.UpdateCanvasSize();
      this.currentBgSize = this.$refs.bg.offsetWidth;
        this.bgRatio = (this.currentBgSize / this.defaultBgSize).toFixed(2);
        this.currentSize = window.innerWidth;
    },

    SubmitNeon()
  {

    this.neonHandler.SetNeon(null);
  },

    GetDefaultBackground() {
      return this.neonHandler.background_sim;
    }



},


    created() {
        window.addEventListener("resize", this.ResizeListener);

        this.calculateur = this.neonHandler.calculateur;
        this.calculateur.SetBackgroundCallBack(this.SetDefaultBackground);

        this.calculateur.GetData().then((result) => {
          if(result ===false)this.background_loaded = true;
            this.show = true;
            setTimeout(this.ExitFullscreen,2000);

        }).catch((err) => {
          console.log(err);

          switch(err.code)
          {
            case "unavailable" : this.error_message = "Connexion au serveur impossible"
          }
          this.error_loading = true;
        })


    },
    beforeDestroy()
    {
      this.$el.removeEventListener("fullscreenchange", this.ExitFullscreen);

    },
    destroyed() {
        window.removeEventListener("resize", this.ResizeListener);
        for (let i = 0; i < this.buttons.length; i++)
      {
        this.buttons[i].removeEventListener('click', this.ShowMenu.bind(this, this.buttons[i].dataset.menu));
      }

    },

    mounted()
    {
      //this.$storageHandler.LogSim();
      //this.$el.requestFullscreen();
      this.$el.addEventListener("fullscreenchange", this.ExitFullscreen);
      this.settings = document.getElementById("settings");
      this.specs = document.getElementsByClassName('sim-dropdown');
      this.menu = document.getElementById("specs");
      this.base = document.getElementById("base");
      this.buttons = document.getElementsByClassName("sim-button");
      this.canvas = document.getElementById("canvas");
      this.$refs.etalon.Init(this.canvas);
      this.defaultBgSize = 1144;
      this.currentBgSize = this.$refs.bg.offsetWidth;
      this.bgRatio = (this.currentBgSize/this.defaultBgSize).toFixed(2);
      this.defaultSize = window.innerWidth;
      this.currentSize = window.innerWidth;

      for (let i = 0; i < this.buttons.length; i++)
      {
        this.buttons[i].addEventListener('click', this.ShowMenu.bind(this, this.buttons[i].dataset.menu));
      }

      //this.menu.addEventListener('click', this.ResetMenu.bind(this));

        const textInput = document.getElementById("inputT");
        this.input = textInput;

       if(this.$storageHandler.IsSimLogged())
        {

          this.first_action = true;

        }





/*#endregion */


}}
</script>


<style  scoped>

.rainbow-bg{
		animation: rainbow-bg 7s linear;
		animation-iteration-count: infinite;
}


@keyframes rainbow-bg{
		100%,0%{
			background-color: rgb(255,0,0);
		}
		8%{
			background-color: rgb(255,127,0);
		}
		16%{
			background-color: rgb(255,255,0);
		}
		25%{
			background-color: rgb(127,255,0);
		}
		33%{
			background-color: rgb(0,255,0);
		}
		41%{
			background-color: rgb(0,255,127);
		}
		50%{
			background-color: rgb(0,255,255);
		}
		58%{
			background-color: rgb(0,127,255);
		}
		66%{
			background-color: rgb(0,0,255);
		}
		75%{
			background-color: rgb(127,0,255);
		}
		83%{
			background-color: rgb(255,0,255);
		}
		91%{
			background-color: rgb(255,0,127);
		}
}




.article-panier-button {
    color : white;
    align-self : center;
    font-family: 'DIN', sans-serif;
    font-size: 1.3rem;
    font-weight : 400;
    display : flex;
    outline : none;
    align-items : center;
    justify-content: center;
    background-color : var(--amaranth);
    border : 1px solid var(--amaranth);
    width : 210px;
    transition-duration: 0.2s;
    height : 50px;
    margin-top: 2rem;
}

.article-panier-button:hover {
    background-color: white;
    color : var(--amaranth);

}

.article-modal-content {
    padding: 1rem;
    width: 100%;
    display : flex;
    flex-direction: column;
    color : black;
    align-items : center;
    justify-content : center;
}

.sim-info {
  padding:0 1rem;
  color: black;
}


.fade-out {
  animation: fadeOut ease-out 0.4s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  99% {
    z-index : 10;
  }
  100% {
    z-index : -10;
    opacity:0;

  }
}



.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

.loading-error {
  color:white!important;
}

.loading-page-error {
  background-color : var(--amaranth)!important;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #2c3e50;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #2c3e50,
      .5em 0 0 #2c3e50;}}


.loading-page {
  position: absolute;
  width: 100vw;
  max-width : 100%;
  min-height : 100vh;
  height : 100%;
  pointer-events: all;
  background-color : rgba(255, 255, 255, 1);
  font-family : 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index : 12;
}

.loading-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-page-content > h1 {
font-weight: 900;
margin-bottom: 1rem;
}


/* width */
.fonds-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.fonds-container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.fonds-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.fonds-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fonds-container {
  width: 90%;
  display: grid;
  background-color: lightgray;
  margin-bottom: 1rem;
  max-height : 276px;
  pointer-events: all;
  overflow-y: auto;
  grid-template-columns : 50% 50%;

}

.fond-item {
  height: 138px;
  width : 100%;
  overflow: hidden;
  display: flex;

}

.fond-item > img {
    height: 100%;
    width: 100%;
    object-fit : cover;
    pointer-events: all;
    cursor: pointer;
    transition-duration : 0.3s;
}

.fond-item > div {
  height: 100%;
    width: 100%;
    object-fit : cover;
    pointer-events: all;
    cursor: pointer;
    transition-duration : 0.3s;
}

.fond-item > img:hover{
  transform: scale(1.3);
}

.sim-show-menu {
  width: 100%!important;
  height : 100%!important;
  display : flex!important;
}

.sim-hide-menu {
  width: 0%!important;
  height : 0%!important;
  display : none!important;
}

/**.color-text
   ShowMenu : function(el)
    {

      if (this.menu.style.left == "100%")
      {
        el.style.width = "100%";
        el.style.height= "100%";
        el.style.display='flex';
        this.neonHandler.EnterTmpMode()
        this.specPricing = true;
        //this.SaveState();
        this.base.style.pointerEvents = "none";
        this.menu.style.left = "0%";
        return
      }
    },

   HideMenu : function(validate)
    {
      for(let i = 0; i < this.specs.length; i++)
      {
        this.specs[i].style.width = '0px';
        this.specs[i].style.height = '0px';
        this.specs[i].style.display = 'none';

      }

      this.menu.style.left = "100%";
      this.base.style.pointerEvents = "all";
      this.neonHandler.QuitTmpMode(validate);
       this.specPricing = false;
    },

 */



#canvas {
  display: none;
}

.color-text {
  transition-duration: 0.2s;
  font-size : 12px;
  color: black;
  transition-timing-function: ease-out;
}




.color-active {
  font-weight : 900;
  color: black;
}

.save-button {
 align-self: center;
 margin-bottom: 1rem;
 border-radius : 4px;
 font-family : 'Montserrat', sans-serif;
 font-size : 14px;
}

input:focus, textarea:focus, select:focus{
        outline: none;
    }

.panier-container {
  margin-left : 1rem;
}

.sim-alignment-container {
  background-color: var(--white-break);
    border-radius: 4px;
    display: flex;
    width: 125px;
    justify-content: space-between;
    align-items: center;
    padding: 0.15rem;
    margin-left: auto;
    margin-right: 1rem;

    transition-duration: 0.2s;
}

.sim-alignment-container > img {
  cursor : pointer;
}

.sim-alignment-container > img:hover {
  background-color: #E1F6FF;
}

@media(max-width:800px)
{
  .wrapper-800 {
    height: 1200px;
  }

  .sim-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%!important;
  }

  .fond-container {
    display: none;
  }

  .panier-container {
    display: flex;
    justify-content: center;
    margin-left: 1rem!important;
  }
  .sim-bg-button {
    width: 100%!important;
    left: 0rem!important;

  }

  .menu-base {
    width: 100%!important;
  }

  .sim-fonds{
    bottom: 60%!important;
  }

  .sim-bg {
    background-position:center top!important;
  }


  .top-menu {
    position: relative!important;
    top: unset!important;
    right: unset!important;
    width: 100%!important;
    max-width: unset!important;
    height: auto!important;
    min-height: 625px!important;
  }
}



.sim-return {
    color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: 0.75rem;
    border-radius: 30px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    transition-duration: 0.2s;
    cursor: pointer;
    display: flex;
}
.sim-return > img {
  filter : brightness(0.5);
}

.sim-return:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

.sim-container {
  width: 100vw;
  display: flex;
  max-width: 100%;
  height: 100vh;
  position: relative;
  background-color: cornflowerblue;
}


.sim-bg {

  background-size: 100% auto;
  position: relative;
  background-position: center;
  width: 100%;
  border-radius: 4px;
  height: 100%;

;





  /*background-image: url('../assets/imgs/bricks.jpg');*/

}

/*#region MENU */


.menu-spec {
  display: flex;
    z-index: 3;
    position: absolute;
    background-color: white;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition-duration: 0.2s;
}

.spec-pricing-container {
        position: absolute;
    bottom: 70px;

    display: flex;
       z-index : 1;
    align-items: center;
    height: 42px;
    background-color: white;
    overflow: hidden;
    /* padding: 1rem; */
    width: 130px;
    border-radius: 10px 0px 0px 10px;
    transition-duration: 0.5s;
}


.spec-pricing {
    height: 100%;
    margin: 0;
    width: 40%;
    color: white;

    display: flex;
    justify-content: flex-end;
    padding-right: 0.3rem;
    background-color: var(--amaranth);
    align-items: center;
    text-align: center;

}

.spec-pricing-prix {
  display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
}

.menu-base {

  overflow-y: auto;
  overflow-x : hidden;
  height: 100%;
  width: 321px;
  margin-left: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.top-menu {
  position: relative;
  transition-duration: 0.5s;

  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
}

.menu-settings{
   position: relative;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
    box-shadow: 3px 3px 5px 0 rgba(0,0,0,.51);
}

.menu-img-debug {
    width : 70px;
    height: 70px;
    background-color: lightcoral;
   margin-bottom: 1rem;
   border: 1px solid;
    overflow: hidden;
    border-radius : 300px;
}


.sim-button {

    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    color: #7B7B7B;
    margin-bottom: 2rem;
    position: relative;
    cursor: pointer;
    transition-duration: 0.2s;
}

.onglet-neon-dot {
  width: 15px;
    height: 15px;
    border-radius: 15px;
    margin: auto;
}


.sim-button > span {
  transition-duration: 0.2s;
  text-align: center;
  font-size: 0.8rem;
  width: 100%;
  font-weight: 500;
  letter-spacing : -0.04rem;
  min-height: 1.6rem;
  overflow: hidden;
}


.sim-input-container {
   margin-top: 1.5rem;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.sim-input {

  color: rgba(0,0,0,.51);
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: start;
  border-radius: 5px;
  box-shadow: 2px 1px 4px 0px rgba(0,0,0,0.26);
  padding: 0.75rem 1rem;
  width: 80%;
}

.sim-input > textarea {
      width: 100%;
    border: none;
    resize: none;
    min-height: 45px;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}

.sim-input > img {
  width: 15px;
  height: 15px;
  margin-right: 0.25rem;
  transition-duration: 0.2s;
  image-rendering: -webkit-optimize-contrast;
  cursor: pointer;
}

.sim-input > img:hover {
  transform: scale(1.1);
}

.sim-input-container > span {
  margin-top: 0.5rem;
  color:var(--amaranth);
  font-weight: 100;
  font-size: 0.75rem;
}

.pricing
{

  margin-top: auto;
  color : black;
  font-size: 1.1rem;
  font-family: 'Montserrat', sans-serif;
}

.pricing > .sim-price {
  font-size: 2.0rem;
  font-weight: bold;
  margin-left : 0.5rem;
  color : var(--amaranth);
}

.sim-button-1 {
    background-color: #2a2a2a;
    color: white;
    /* width: 90%; */
    padding: 0.5rem 1.75rem;
    pointer-events: all;
    font-weight: 200;
    letter-spacing: -0.03rem;
    border-radius: 4px;
    cursor: pointer;
    transition-duration: 0.2s;
}

.sim-button-1:hover {
  transform: scale(1.1);
}

.sim-price > span
{
  color: var(--amaranth);
  font-weight: bold;
  font-size: 1.5rem;
}


.sim-dropdown {
    transition-duration: 0.2s;
    display: flex;
    flex-direction: column;
    width:0px;
    height:0px;
    display:none;
    align-items: center;
}

.sim-dropdown-return {
  margin-top: auto;
  cursor: pointer;
  border-radius: 30px;
  transform: rotateZ(-90deg);
  transition-duration: 0.2s;
  pointer-events: all;
}

.sim-dropdown-return:hover {
  background-color: rgba(0, 0, 0, 0.3);
}


.sim-polices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  pointer-events: all;
  overflow-y : auto;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}



.sim-colors-wrapper {

  display: flex;
  width: 100%;
  max-height :(100% - 20px);
  overflow-y: scroll;
  pointer-events: all;
  height: 100%;

  justify-content: center;
}

.sim-couleurs {
  margin-top : 1rem;
  padding-bottom: 1rem;
  display: grid;
  width: 80%;
  margin-bottom: 1rem;
  pointer-events: all;
  grid-template-columns: 50% 50%;

}



.sim-support {
  width: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
  pointer-events: all;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.sim-support-img {
  display: grid;
  width: 80%;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 100px 100px 100px;

}

.sim-support-img  > div {
  margin : 1rem 0;


}

.sim-support-round{
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.sim-support-round > span {
  margin-right: 1rem;
}

.sim-support-img > div > img {
  transition-duration: 0.2s;
  cursor: pointer;
}

.sim-support-img > div > img:hover {
  transform: scale(1.1);
}

.sim-support-img > div {
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.color-support {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}
.color-support > span {
  margin-bottom: 1rem;
}

.sim-options {
  display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    pointer-events: all;
    font-family: 'Montserrat', sans-serif;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
}

.sim-options > * {
  box-sizing: border-box;
}

.sim-options > span {
  display: flex;
  align-items: center;
}

.neon-type {
  flex-direction: row;
    display: flex;
    width: 100%;
    padding: 1rem 1rem;
    justify-content: center;
    pointer-events: all;
    border-bottom: 1px solid gray;
}
/*
.neon-type > div {
  padding: 0.5rem 1.25rem;
  font-size: 12px;
  max-height: 34px;
  display: flex;
  align-items: center;
  background-color: #FAFAFA;
  box-shadow: 3px 3px 3px 0 rgba(0,0,0,.31);
  border-radius: 30px;
  cursor: pointer;
  background-color: #2a2a2a;
  color: white;
  transition-duration: 0.2s;

}
*/
.neon-tel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:1rem 0;
  width: 100%;
  border-bottom: 1px solid gray;
}

.neon-tel > span {
  margin-right: 1rem;
}

.neon-type >div:hover{
  transform: scale(1.1);
}

.sim-prises {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid gray;
  font-size: 12px;
  pointer-events: all;
}

.sim-message-title {
  border-top: 1px solid gray;
  padding-top : 1rem;
  padding-left : 1rem;
  width : 100%;
  text-align : left;
}

.sim-message {
  padding :0 1rem;

  width: 100%;
  height : 150px;

}

.form-select-sm {
  padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: .875rem;
}

.form-select {
  display: block;
    width: 150px;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("../assets/imgs/select.svg");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


.sim-message > textarea {

  height : 100%;
  min-height : 120px;
  font-size: 0.85rem;
  pointer-events : all;
}

.sim-prises > div {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
}

.sim-prises > div > img {
  transition-duration: 0.2s;
  cursor: pointer;
}

.sim-prises > div > img:hover {
  transform: scale(1.1);
}

.sim-livraison {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 10%;
  justify-content: center;
  pointer-events: all;
  width: 100%;
}
/*
.sim-livraison > div {
  padding: 0.5rem 1.25rem;
  margin: 0 0.5rem;
  font-size: 12px;
  max-height: 34px;
  display: flex;
  align-items: center;
  background-color: #2a2a2a;
  color: white;
  box-shadow: 3px 3px 3px 0 rgba(0,0,0,.31);
  border-radius: 30px;
  cursor: pointer;

  transition-duration: 0.2s;

}

.sim-livraison > div:hover {
  transform: scale(1.1);
}*/


/*#endregion */


.sim-align-options {
 display: flex;
 flex-direction: column;
 font-family: 'Montserrat', sans-serif;
 margin: 1rem 0.25rem;
 font-size: 14px;
 width: 100%;
}

.sim-align-options > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.sim-align-options-span {
    cursor: pointer;
  pointer-events: all;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  background-color: #2a2a2a;
  color: white;
  border-radius: 30px;
  margin: 0.5rem 0.25rem;
  padding: 0.75rem 1.25rem;
}










.sim-fonds{
 position: relative!important;
 top : unset!important;
 left:unset!important;
 margin-top:2rem;
}

.dimensions-list {
    display: grid;
    margin : 0rem auto;
    pointer-events: all;
    grid-template-columns : 50% 50%;
    align-items: center;
    width: 70%;
}










.dimensions-list > li:hover {
  background-color: rgba(65, 124, 252, 0.3);
}

.dimensions-list > .selected {
  background-color: rgba(65, 124, 252, 0.3);
}


.dimensions-size {
  margin: 1rem 0;
}

.disabled-dim {
  background-color : lightgrey!important;
  pointer-events: none!important;

}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  max-height:20px!important;
  border-radius:30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/*#region LEFT CONTAINER */

.sim-bg-container {
  width: 100%;
  height: 100%;
  background-color : #F6F6F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.sim-bg-button {
  position: absolute;
  bottom: 0.5rem;
  height: 52px;
  overflow: hidden;
  left: 0rem;
  padding: 0 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.montserrat {
  font-family : 'Montserrat', sans-serif
}


/*#endregion */

/*#region ONGLETS */

.sim-onglets-container {
  width: 100%;
  height: 45px;
  display: flex;
  z-index: 2;
  margin-bottom: 1rem;
}



.sim-onglets-double-wrapper {
  overflow: hidden;
  width: 100%;
  height: 45px;
}

.sim-onglets-wrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  height: 100%;
}




/*#endregion */


.sim-delete {
pointer-events: all;
margin-top: auto;
  display: flex;
  align-items: center;
  border-top : 1px solid rgba(0, 0, 0, 0.4);
  transition-duration: 0.4s;
  background-color : #FFC2C2;
  cursor: pointer;
  width: 100%;
  height: 50px;

}

.sim-delete-disabled {
  background-color :#888!important;
  pointer-events: none!important;

}

.sim-validate {
  pointer-events: all;
margin-top: auto;
  display: flex;
  align-items: center;
  border-top : 1px solid rgba(0, 0, 0, 0.4);
  border-right : 1px solid rgba(0, 0, 0, 0.4);
  transition-duration: 0.4s;
  background-color : #00a693;
  cursor: pointer;
  width: 100%;
  height: 50px;
}
.sim-validate:hover {
  background-color: #a8ff61;
}

.sim-validate:hover >img {
  filter: brightness(1)!important;
}

.sim-validate > img {
  width:20px;
  height:20px;
  margin:20px auto;
  transition-duration: 0.4s;
}


.sim-delete:hover {
  background-color: #ff6961;
}

.sim-delete:hover >img {
  filter: brightness(1)!important;
}

.sim-delete > img {
  width:15px;
  height:15px;
  margin:12px auto;
  transition-duration: 0.4s;
}

.sim-menu-fonction{
  width : 100%;
  max-width: 275px;
  margin: 0 auto;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 33.333% 33.333% 33.333%;
}


.onglet-neon-container {
  margin-top : 1rem;
  margin-left: 1rem;
  min-height:40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;

}

.onglet-neon-container > span {
  font-size: 17px;
  color: #7B7B7B;
  white-space: nowrap;
}

.onglet-neon-content {
  display: flex;
  flex-wrap: nowrap;
  pointer-events: none;
  padding-right : 1rem;
}

.dropdown-title {
      width: 100%;
    padding: 0.7rem 0;
    background-color: #E7E7E7;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    margin-bottom: 1rem;
}


</style>

<style>


.onglet-neon {
  color: #7B7B7B;
  width: 51px;
  display: flex;
  pointer-events: all;
  white-space: nowrap;
  justify-content:center;
  overflow: hidden;
  text-overflow:clip;
  margin : 0 0.3rem;
  padding:0.3rem 0.75rem;
  cursor: pointer;
  border-radius: 50px;
  border : 1px solid #7B7B7B;
  transition-duration: 0.4s;

}

.onglet-neon:hover {
  background-color:#E1F6FF;
}

.onglet-neon-active {
    background-color:#E1F6FF;
}

.sim-onglet {
  background-color : white;
  width: 50%;
  height : 100%;
  overflow: hidden;
  text-align: start;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  box-sizing: content-box;
  border-bottom: 1px solid transparent;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  border-left: 1px  solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition-duration: 0.2s;
}

.sim-onglet-bottom {
  background-color : #D2D2D2;
  color : #7B7B7B;
  box-shadow: inset 0px -3px 11px 2px rgba(0,0,0,0.4);
}



.sim-onglet:hover {
  background-color: #c6d8fe;
}



.disabled {
  background-color: gray!important;
  cursor: default!important;
  pointer-events: none!important;
}

.disabled:hover {
  transform: none!important;
}

.error-sub {
  color : white;
  font-weight: 500;
}

.sim-button-classic {

  padding: 0.75rem 1rem;
  margin:1rem 0rem;

  border-radius:4px ;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.85rem;
  transition-duration: 0.2s;
  pointer-events: all;
  color: rgba(65, 124, 252,1);;
  background-color: white;
  border : 1px solid rgba(65, 124, 252,1);

}



.sim-button-classic:hover {
  cursor: pointer;
  background-color:rgba(65, 124, 252,1);
  color:white;
  border : 1px solid rgba(65, 124, 252,1);

}

.sim-texts {
  display: flex;
  pointer-events: all;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

.sim-text-selected {
  background-color:  rgba(65, 124, 252, 0.3);
}

.texts-transform {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  pointer-events: none;
  align-items: center;
   font-family: 'Montserrat', sans-serif;
}


.sim-texts > div {
  display: flex;
  width: 100%;
}

.sim-align {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 100%;
}

.sim-align > span {
  margin-top: 1rem;
  font-size: 14px;
}

.btn-black-small {
font-family: 'Montserrat', sans-serif;
  pointer-events: all;
  font-size: 12px;
  background-color: #2a2a2a;
  color: white;
    border-radius: 30px;
    padding: 0.5rem 0.75rem;
}

.btn-black {
  font-family: 'Montserrat', sans-serif;
  pointer-events: all;
  padding: 0.75rem 1.25rem;
  box-shadow: 3px 3px 3px 0 rgba(0,0,0,.31);
  background-color: #2a2a2a;
  color: white;
  cursor: pointer;
  border-radius: 30px;
  margin: 0.5rem;
  transition-duration: 0.2s;

}

.btn-black:hover {
  transform: scale(1.1);
}

.neon-span:hover {
  background-color: rgba(0,0,0,0.2);
}

.neon-span {
  padding: 0.75rem 0.25rem;
  width: 100%;
  min-height: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid gray;
  transition-duration: 0.2s;
  pointer-events: all;
  cursor: pointer;
}


.sim-polices > span {
    padding: 0.75rem 0rem;
    min-height: 70px;
    text-align: center;
    display: flex;
    margin : 0.5rem;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    pointer-events: all;
    transition-duration: 0.2s;

}
.sim-polices > span:hover {
  background-color: rgba(0,0,0,0.2);
}

.dot {
  margin:0.5rem auto;
  border-radius: 30px;
  width: 60px;
  height:60px;
  background-color: salmon;
  box-shadow: 3px 3px 2px 0px rgba(133, 103, 103, 0.42);
  transition-duration: 0.2s;
  cursor: pointer;
  pointer-events: all;

}

.sim-color-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family : 'Montserrat', sans-serif;
  margin-bottom: 1rem;
}

.sim-color-item > span {
  margin-top : 0.25rem;
  font-size : 14px;
  color : #707070;
}

.sim-active {
  outline: 2px solid cornflowerblue;
}

.dot:hover {
  transform: scale(1.05);
}




</style>
